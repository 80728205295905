<script lang="ts">
  import Icon from '@iconify/svelte'
  import { Accordion, AccordionItem, getModalStore, getToastStore } from '@skeletonlabs/skeleton'
  import type { SvelteComponent } from 'svelte'

  import { LabelingType } from '@src/routes/optimization-datasets/optimization-datasets-store.svelte'
  import { ClassificationRunArgs, ObjectDetectionRunArgs } from '@src/utils/run-args.svelte'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  type AccordionEvent = CustomEvent<{
    event?: Event
    id: string
    panelId: string
    open: boolean
    autocollapse: boolean
  }>

  let { parent }: Props = $props()

  let useAdvanceRunOptions = $state(false)

  // Add support for segmentation
  let runArgs = $state<ClassificationRunArgs | ObjectDetectionRunArgs | null>(null)

  $effect(() => {
    if ($modalStore[0].value.labelingType === LabelingType.ObjectDetection) {
      runArgs = new ObjectDetectionRunArgs()
    } else {
      runArgs = new ClassificationRunArgs()
    }
  })

  const modalStore = getModalStore()

  let cannotUseAdvancedRunOptions = $derived(
    $modalStore[0].value.labelingType === LabelingType.SpeechToText,
  )

  const toastStore = getToastStore()
  let activeToast: string | null = null

  const onFormCancel = () => {
    if ($modalStore[0].response) {
      $modalStore[0].response(false)
    }
    modalStore.close()
  }
  const onFormSubmit = () => {
    if (activeToast) {
      toastStore.close(activeToast)
    }

    if ($modalStore[0].response) {
      $modalStore[0].response(
        useAdvanceRunOptions && !cannotUseAdvancedRunOptions ? $state.snapshot(runArgs) : true,
      )
    }
    modalStore.close()
  }

  const accordionChange = (event: AccordionEvent) => {
    useAdvanceRunOptions = event.detail.open
  }
</script>

{#if $modalStore[0]}
  <div class="card w-modal max-h-[90vh] space-y-4 overflow-y-scroll p-4 shadow-xl">
    <header class="text-2xl font-bold">{$modalStore[0].title}</header>
    <article class="space-y-4 border border-surface-500 p-4 rounded-container-token">
      {$modalStore[0].body}
      <Accordion class="card variant-glass-surface mt-4">
        <AccordionItem on:toggle={accordionChange}>
          {#snippet lead()}
            <Icon icon="iconoir:settings" class="h-6 w-6" />
          {/snippet}
          {#snippet summary()}
            <h3>Advanced run options</h3>
          {/snippet}
          {#snippet content()}
            <form class="space-y-4">
              {#if cannotUseAdvancedRunOptions}
                <p>No advanced options are available for Speech to Text datasets currently</p>
              {:else if runArgs}
                <div>
                  <div class="columns-2">
                    <div>
                      <label class="label" for="imgWidth">Image width</label>
                      <input
                        id="imgWidth"
                        name="imgWidth"
                        type="number"
                        class="input my-0 box-border flex w-fit px-2 py-1"
                        step={1}
                        bind:value={runArgs.imgSize[0]}
                      />
                    </div>
                    <div>
                      <label class="label" for="imgHeight">Image height</label>
                      <input
                        id="imgHeight"
                        name="imgHeight"
                        type="number"
                        class="input my-0 box-border flex w-fit px-2 py-1"
                        step={1}
                        bind:value={runArgs.imgSize[1]}
                      />
                    </div>
                  </div>
                  <aside class="alert variant-filled-surface m-2">
                    <div class="alert-message">
                      <p>
                        It is recommended to keep the image size at 224 for width and 224 for
                        height.
                      </p>
                      <p>
                        If instances of a given class are known to be small relative to the whole
                        image, you might want to consider increasing the image size.
                      </p>
                      <p>
                        Mostly relevant for classification problems or when the crop ratio is not
                        1.0
                      </p>
                    </div>
                  </aside>
                </div>
                <div class="grid grid-cols-[auto,1fr] gap-2">
                  <input
                    id="upsample"
                    name="upsample"
                    type="checkbox"
                    class="checkbox my-0"
                    bind:checked={runArgs.upsample}
                  />
                  <label class="label" for="upsample">Oversample under represented classes</label>
                </div>
              {/if}
              {#if runArgs instanceof ObjectDetectionRunArgs}
                <div class="columns-2">
                  <div>
                    <label class="label" for="minAbsBboxSize">Minimum absolute bbox size</label>
                    <input
                      id="minAbsBboxSize"
                      name="minAbsBboxSize"
                      type="number"
                      class="input my-0 box-border flex w-fit px-2 py-1"
                      step={1}
                      bind:value={runArgs.minAbsBboxSize}
                    />
                  </div>
                  <div>
                    <label class="label" for="minAbsBboxArea">Minimum absolute bbox area</label>
                    <input
                      id="minAbsBboxArea"
                      name="minAbsBboxArea"
                      type="number"
                      class="input my-0 box-border flex w-fit px-2 py-1"
                      step={1}
                      bind:value={runArgs.minAbsBboxArea}
                    />
                  </div>
                </div>
                <div class="columns-2">
                  <div>
                    <label class="label" for="minRelBboxSize">Minimum relative bbox size</label>
                    <input
                      id="minRelBboxSize"
                      name="minRelBboxSize"
                      type="number"
                      class="input my-0 box-border flex w-fit px-2 py-1"
                      step={0.01}
                      bind:value={runArgs.minRelBboxSize}
                    />
                  </div>
                  <div>
                    <label class="label" for="minRelBboxArea">Minimum relative bbox area</label>
                    <input
                      id="minRelBboxArea"
                      name="minRelBboxArea"
                      type="number"
                      class="input my-0 box-border flex w-fit px-2 py-1"
                      step={0.01}
                      bind:value={runArgs.minRelBboxArea}
                    />
                  </div>
                </div>
                <div>
                  <div class="grid grid-cols-[auto,1fr] gap-2">
                    <input
                      id="cropFrame"
                      name="cropFrame"
                      type="checkbox"
                      class="checkbox my-0"
                      onchange={(event) => {
                        if (runArgs instanceof ObjectDetectionRunArgs) {
                          if (event.currentTarget.checked) {
                            runArgs.cropRatio = null
                          } else {
                            runArgs.cropRatio = 1.0
                          }
                        }
                      }}
                    />
                    <label class="label" for="cropFrame">Do not crop the frame</label>
                  </div>
                  {#if runArgs.cropRatio !== null}
                    <label class="label" for="cropRatio">Crop ratio</label>
                    <input
                      id="cropRatio"
                      name="cropRatio"
                      type="number"
                      class="input my-0 box-border flex w-fit min-w-96 px-2 py-1"
                      step={0.01}
                      min={1}
                      bind:value={runArgs.cropRatio}
                    />
                  {/if}
                  <aside class="alert variant-filled-surface m-2">
                    <div class="alert-message">
                      <p>Change this value at your own risk.</p>
                      <p>It is recommended to keep it at 1.0 unless you know what you are doing</p>
                    </div>
                  </aside>
                </div>
                <div>
                  <div class="grid grid-cols-[auto,1fr] gap-2">
                    <input
                      id="addMaskChannel"
                      name="addMaskChannel"
                      type="checkbox"
                      class="checkbox my-0"
                      bind:checked={runArgs.addMaskChannel}
                    />
                    <label class="label" for="addMaskChannel">Add mask channel</label>
                  </div>
                  <aside class="alert variant-filled-surface m-2">
                    <div class="alert-message">
                      <p>Change at your own risk.</p>
                      <p>
                        It is recommended to keep it at un-checked unless you know what you are
                        doing
                      </p>
                      <p>If the crop ratio is greater than 1.0, it is recommended to check this.</p>
                    </div>
                  </aside>
                </div>
              {/if}
            </form>
          {/snippet}
        </AccordionItem>
      </Accordion>
    </article>
    <footer class="modal-footer {parent.regionFooter}">
      <button class="btn {parent.buttonNeutral}" onclick={onFormCancel}
        >{parent.buttonTextCancel}</button
      >
      <button class="btn {parent.buttonPositive}" onclick={onFormSubmit}
        >{parent.buttonTextConfirm}</button
      >
    </footer>
  </div>
{/if}
