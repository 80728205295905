<script lang="ts">
  import Icon from '@iconify/svelte'
  import {
    Accordion,
    AccordionItem,
    Autocomplete,
    type AutocompleteOption,
    InputChip,
    popup,
    type PopupSettings,
    ProgressRadial,
  } from '@skeletonlabs/skeleton'
  import { getModalStore, getToastStore } from '@skeletonlabs/skeleton'
  import { onMount, type SvelteComponent } from 'svelte'

  import {
    COCO,
    DatasetMetadataType,
    datasetMetadataTypesValues,
    displayLabelingTypes,
    displayMetadataTypes,
    HirundoCSV,
    type IOptimizationDataset,
    isCOCO,
    isHirundoCSV,
    isYOLO,
    LabelingType,
    labelingTypesValues,
    runOptimization,
    YOLO,
  } from './optimization-datasets-store.svelte'
  import Select from '@components/Select.svelte'
  import {
    organizationAsyncState,
    organizationsStore,
  } from '@src/routes/organizations/organizations-store'
  import {
    createStorageConfig,
    deleteStorageConfig,
    getStoragePrefix,
    type IStorageConfig,
    prepareInputValuesForQuery,
    storageConfigStore,
  } from '@src/routes/storage-configs/storage-configs-store'
  import StorageFormFields from '@src/routes/storage-configs/StorageFormFields.svelte'
  import { loggedInStore, User } from '@stores/logged-in-store'
  import { ClassesOption } from '@utils/misc'
  import {
    NAME_PATTERN,
    nameValidator,
    NOT_URL_PATTERN,
    ValidityState,
  } from '@utils/validation.svelte'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  let { parent }: Props = $props()

  const modalStore = getModalStore()
  const toastStore = getToastStore()

  const currentUser: User = $loggedInStore

  let optimization: IOptimizationDataset | null = $modalStore[0].value
  let currentOrganizationId = optimization?.organizationId ?? currentUser.primaryOrganizationId
  let selectedStorageConfig: IStorageConfig | null = $state(null) // For selected storage in search input
  let datasetFormElement: HTMLFormElement | undefined = $state()

  type OptimizationInput = Partial<
    Pick<IOptimizationDataset, 'name' | 'language' | 'organizationId'>
  > &
    Pick<IOptimizationDataset, 'dataRootUrl' | 'labelingInfo'> & {
      labelingType: LabelingType | null
      classes: string[]
      selectedStorageConfigId?: number | null
      selectedStorageConfigName: string
    }

  let optimizationInputValues: OptimizationInput = $state({
    name: '',
    organizationId: currentOrganizationId,
    selectedStorageConfigName: '',
    dataRootUrl: '',
    labelingType: null,
    labelingInfo: new HirundoCSV(),
    classes: [],
  })
  let invalids = $state({
    name: new ValidityState(),
    selectedStorageConfigName: new ValidityState(),
    labelingType: new ValidityState(),
    csvUrl: new ValidityState(),
    jsonUrl: new ValidityState(),
    dataRootUrl: new ValidityState(),
    dataYamlUrl: new ValidityState(),
    labelsDirUrl: new ValidityState(),
  })

  let sortedStorageConfigs = $derived(
    Array.from($storageConfigStore.values()).sort((a, b) => a.name.localeCompare(b.name)),
  )

  let selectedClassesOption: ClassesOption = $state(ClassesOption.AutoDetection)
  let isAccordionOpen = $state(false)
  let isEditMode = $state(false)
  let isLoading = $state(false)

  // Values for the storageFormFields component
  let storageFormElement: HTMLFormElement | undefined = $state()
  let storageConfig = optimization?.storageConfig
  let selectedStorageType = $state(storageConfig?.type || null)
  let storageConfigName = $state(storageConfig?.name || '')
  let storageInputValues = $state({}) // TODO: type more strictly

  onMount(async () => {
    await initializeInputValues(optimization)
  })

  const initializeInputValues = async (optimization: IOptimizationDataset | null) => {
    if (!optimization) return

    selectedStorageConfig = optimization.storageConfig
    isEditMode = true // Disables the accordion and search input
    if ((optimization.classes?.length ?? 0) > 0) selectedClassesOption = ClassesOption.ManualInput

    const {
      name,
      labelingType,
      language,
      organizationId,
      dataRootUrl,
      labelingInfo,
      classes = [],
    } = optimization

    optimizationInputValues = {
      name,
      labelingType,
      language,
      organizationId,
      selectedStorageConfigId: selectedStorageConfig?.id,
      selectedStorageConfigName: selectedStorageConfig?.name,
      dataRootUrl,
      labelingInfo,
      classes: classes || [],
    }
  }

  const submitStorageConfigFields = async () => {
    if (!storageFormElement?.reportValidity()) return
    if (!selectedStorageType) {
      console.error('No storage type selected')
      return
    }

    const storageConfigPayload = prepareInputValuesForQuery(storageInputValues, selectedStorageType)
    storageConfigPayload.organizationId = currentOrganizationId
    storageConfigPayload.name = storageConfigName
    storageConfigPayload.type = selectedStorageType

    const newStorageConfig = await createStorageConfig(
      storageConfigPayload as IStorageConfig,
      toastStore,
      currentUser.userId,
      currentUser.username,
    )
    if (!newStorageConfig) {
      console.error('Failed to create new storage config')
      return
    }
    return newStorageConfig
  }

  const createDataset = async () => {
    let createdStorageConfig = false
    try {
      if (isAccordionOpen) {
        const storageConfigCreated = await submitStorageConfigFields()
        if (storageConfigCreated) {
          createdStorageConfig = true
        } else {
          console.error('Failed to create new storage config from datsaset form')
          throw new Error('Failed to create new storage config from datsaset form')
        }
        optimizationInputValues.selectedStorageConfigId = storageConfigCreated.id
      }
      if (!optimizationInputValues.labelingType) {
        invalids.labelingType.setInvalid('Labeling type is required')
        throw new Error('Labeling type is required')
      }
      const payload = {
        name: optimizationInputValues.name,
        organizationId: optimizationInputValues.organizationId,
        labelingType: optimizationInputValues.labelingType,
        ...(optimizationInputValues.labelingType == LabelingType.SpeechToText
          ? { language: optimizationInputValues.language }
          : {}),
        storageConfigId: optimizationInputValues.selectedStorageConfigId,
        dataRootUrl: optimizationInputValues.dataRootUrl,
        labelingInfo: $state.snapshot(optimizationInputValues.labelingInfo),
        classes: optimizationInputValues.classes,
      }

      if (!$modalStore[0].response) {
        console.error("Modal doesn't have a response function")
        throw new Error("Modal doesn't have a response function")
      }
      await $modalStore[0].response({ payload })
      return payload
    } catch (e) {
      if (createdStorageConfig && optimizationInputValues.selectedStorageConfigId) {
        await deleteStorageConfig(optimizationInputValues.selectedStorageConfigId)
        // ⬆️ This cleans up any storage config that was created, enabling the user to retry
      }
      console.error('Failed to create dataset')
      throw e
    }
  }

  const createHandler = async () => {
    if (!datasetFormElement?.reportValidity()) return
    try {
      isLoading = true
      await createDataset()
      parent.onClose()
    } finally {
      isLoading = false
    }
  }

  const createAndRunHandler = async () => {
    if (!datasetFormElement?.reportValidity()) return
    try {
      isLoading = true
      const dataset = await createDataset()
      const datasetId = $modalStore[0].meta.optimizationId
      if (!datasetId) {
        console.error('Failed to create dataset. Could not run optimization')
        isLoading = false
        return
      }
      await runOptimization(
        dataset,
        datasetId,
        toastStore,
        modalStore,
        optimizationInputValues.name,
      )
      parent.onClose()
    } finally {
      isLoading = false
    }
  }

  const onStorageConfigSelect = (event: CustomEvent<AutocompleteOption<string>>) => {
    const selectedStorageConfigName = event.detail.value
    const foundStorageConfig = sortedStorageConfigs.find(
      (storageConfig) => storageConfig.name === selectedStorageConfigName,
    )

    if (foundStorageConfig) {
      selectedStorageConfig = foundStorageConfig
      isAccordionOpen = false
      optimizationInputValues = {
        ...optimizationInputValues,
        selectedStorageConfigId: foundStorageConfig.id,
        selectedStorageConfigName: foundStorageConfig.name,
      }
      invalids.selectedStorageConfigName.setValid()
    } else {
      invalids.selectedStorageConfigName.setInvalid('No storage config with this name found')
    }
  }

  let accordionDisabled = $derived(selectedStorageConfig !== null || isEditMode)

  function handleSearchInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    if (!input) {
      selectedStorageConfig = null // Reset the storage config
      isAccordionOpen = false // Ensure accordion can be reopened
    }
    const foundStorageConfig = sortedStorageConfigs.find(
      (storageConfig) => storageConfig.name === input,
    )
    if (foundStorageConfig) {
      invalids.selectedStorageConfigName.setValid()
    } else {
      invalids.selectedStorageConfigName.setInvalid('No storage config with this name found')
    }
  }

  const sharedInputClasses = 'my-0 box-border flex md:min-w-96 px-2 py-1'

  const storageConfigsListPopup: PopupSettings = {
    event: 'focus-click',
    target: 'popupAutocomplete',
    placement: 'top',
  }

  const chooseStorageConfigPopupHover: PopupSettings = {
    event: 'hover',
    placement: 'right',
    target: 'chooseStorageConfigPopupHover',
  }

  const disabledEditDatasetStoragePopup: PopupSettings = {
    event: 'hover',
    placement: 'top',
    target: 'disabledEditDatasetStorage',
  }

  const prefix = $derived.by(() =>
    getStoragePrefix({
      storageInputValues,
      selectedStorageConfig,
      type: selectedStorageType ?? selectedStorageConfig?.type,
    }),
  )
  const pasteHandler =
    (setField: (value: string) => void) =>
    (e: ClipboardEvent & { currentTarget: HTMLInputElement }) => {
      if (window.getSelection()?.toString() == e.currentTarget.value && e.clipboardData) {
        e.currentTarget.value = decodeURI(e.clipboardData.getData('text/plain').replace(prefix, ''))
        setField(encodeURI(prefix + e.currentTarget.value))
        e.preventDefault()
      }
    }
  const copyHandler = (e: ClipboardEvent & { currentTarget: HTMLInputElement }) => {
    if (
      !window.getSelection() ||
      !window.getSelection()?.toString() ||
      window.getSelection()?.toString() == e.currentTarget.value
    ) {
      e.clipboardData?.clearData()
      e.clipboardData?.setData('text/plain', prefix + encodeURI(e.currentTarget.value))
      e.preventDefault()
    }
  }

  const getInvalidHover: (field: keyof typeof invalids) => PopupSettings = (field) => ({
    event: 'hover',
    placement: 'right',
    target: `invalid${field}Hover`,
  })
</script>

{#snippet invalidIcon(field: keyof typeof invalids, value?: string | null)}
  <div class="input-group-shim">
    {#if invalids[field].invalid}
      <span use:popup={getInvalidHover(field)}>
        <Icon icon="iconoir:help-circle" class="text-tertiary-400" />
      </span>
    {:else if !!value && value !== ''}
      <Icon icon="iconoir:check-circle" class="text-success-400" />
    {:else}
      <Icon icon="iconoir:check-circle" class="text-surface-400" />
    {/if}
  </div>
{/snippet}
{#snippet invalidHoverInfo(field: keyof typeof invalids)}
  <div data-popup={`invalid${field}Hover`}>
    {#if invalids[field].invalid}
      <div class="card variant-filled-tertiary z-50 max-w-80 p-2">
        <p>{invalids[field].reason}</p>
      </div>
    {/if}
    <div class="variant-filled-tertiary arrow"></div>
  </div>
{/snippet}

{#if $modalStore[0]}
  <form
    class="card relative m-3 h-[30rem] w-[48rem] max-w-[80vw] justify-self-center overflow-y-scroll p-6"
    bind:this={datasetFormElement}
    class:dimmed={isLoading}
  >
    {#if isLoading}
      <div
        class="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-secondary-400 bg-opacity-20"
      >
        <ProgressRadial
          stroke={75}
          meter="stroke-surface-200"
          track="stroke-surface-200/30"
          strokeLinecap="round"
          indeterminate={true}
        />
      </div>
    {/if}
    <h3 class="h3 justify-between">
      {$modalStore[0].title}
      <button
        class="absolute right-3 top-3 text-surface-400 hover:text-surface-300"
        type="button"
        onclick={parent.onClose}
      >
        <Icon icon="iconoir:xmark-circle" />
      </button>
    </h3>
    <div class="m-1">{$modalStore[0].body}</div>

    <label for="name">Optimization name:</label>
    <div class="input-group input-group-divider grid-cols-[1fr_auto]">
      <input
        class={sharedInputClasses + ' autocomplete' + (invalids.name.invalid ? ' invalid' : '')}
        type="text"
        id="name"
        oninvalid={nameValidator(invalids.name)}
        onchange={nameValidator(invalids.name)}
        bind:value={optimizationInputValues.name}
        required
        pattern={NAME_PATTERN.source}
      />
      {@render invalidIcon('name', optimizationInputValues.name)}
    </div>
    {@render invalidHoverInfo('name')}

    <label for="organization-to-associate-with">Associated organization:</label>
    {#if $organizationAsyncState.loading}
      <p>Loading organizations...</p>
    {:else if $organizationAsyncState.error}
      <p>{$organizationAsyncState.error}</p>
    {:else}
      <Select
        required
        name="organization-to-associate-with"
        bind:value={optimizationInputValues.organizationId}
      >
        <option selected value>Select organization</option>
        {#each $organizationsStore as [_id, organization] (organization.id)}
          <option
            value={organization.id}
            selected={organization.id === optimizationInputValues.organizationId}
          >
            {organization.name}
          </option>
        {/each}
      </Select>
    {/if}

    <label for="labeling-type">Labeling type:</label>
    <Select
      name="labeling-type"
      placeholder="Select Type"
      required
      class={invalids.labelingType.invalid ? ' invalid' : ''}
      bind:value={optimizationInputValues.labelingType}
    >
      <option selected value={null}>Select type</option>
      {#each labelingTypesValues as labelingType}
        <option value={labelingType}>{displayLabelingTypes[labelingType]}</option>
      {/each}
    </Select>

    <label for="dataset-storage">
      Dataset storage:
      <button disabled class="[&>*]:pointer-events-none" use:popup={chooseStorageConfigPopupHover}>
        <Icon icon="iconoir:help-circle" class="text-tertiary-400" />
      </button>
      <div class="card variant-filled-tertiary z-50 p-2" data-popup="chooseStorageConfigPopupHover">
        <p>Select existing storage config or create a new one</p>
        <div class="variant-filled-tertiary arrow"></div>
      </div>
    </label>
    <div class="relative" use:popup={disabledEditDatasetStoragePopup}>
      <div
        class={`card variant-filled-warning p-2 ${isEditMode ? 'visible' : 'invisible'}`}
        data-popup={'disabledEditDatasetStorage'}
      >
        <p>You cannot change the dataset storage in edit mode</p>
        <div class="variant-filled-warning arrow"></div>
      </div>
      <div class="input-group input-group-divider grid-cols-[1fr_auto]">
        <input
          class={sharedInputClasses}
          type="search"
          name="autocomplete-search"
          placeholder="Search..."
          disabled={isAccordionOpen || isEditMode}
          autocomplete="off"
          required={!isAccordionOpen}
          bind:value={optimizationInputValues.selectedStorageConfigName}
          use:popup={storageConfigsListPopup}
          oninput={handleSearchInput}
        />
        {@render invalidIcon(
          'selectedStorageConfigName',
          optimizationInputValues.selectedStorageConfigName,
        )}
      </div>
      {@render invalidHoverInfo('selectedStorageConfigName')}
      <div
        class="card variant-filled-surface absolute z-50 max-h-48 w-full max-w-sm overflow-y-auto p-4"
        tabindex="-1"
        data-popup="popupAutocomplete"
      >
        <Autocomplete
          bind:input={optimizationInputValues.selectedStorageConfigName}
          options={sortedStorageConfigs.map((storageConfig) => ({
            value: storageConfig.name,
            label: storageConfig.name + ' (' + storageConfig.type + ')',
          }))}
          on:selection={onStorageConfigSelect}
          on:input={handleSearchInput}
        />
      </div>
      <div class="mt-4" class:disable-accordion={accordionDisabled}>
        <Accordion class="card variant-glass-surface">
          <AccordionItem
            on:click={() => (isAccordionOpen = !isAccordionOpen)}
            disabled={accordionDisabled}
          >
            {#snippet summary()}
              Create new storage config
            {/snippet}
            {#snippet iconOpen()}
              <Icon icon="iconoir:plus" />
            {/snippet}
            {#snippet iconClosed()}
              <Icon icon="iconoir:minus" />
            {/snippet}
            {#snippet content()}
              <StorageFormFields
                bind:storageFormElement
                bind:inputValues={storageInputValues}
                bind:selectedStorageType
                bind:storageConfigName
                {storageConfig}
              ></StorageFormFields>
            {/snippet}
          </AccordionItem>
        </Accordion>
      </div>
    </div>

    {#snippet metadataFields({
      fieldName,
      field,
      setField,
      required = true,
    }: {
      fieldName: keyof typeof invalids
      field: string | null
      setField: (newValue: string) => void
      required?: boolean
    })}
      <div
        class={`input input-group input-group-divider ${
          required ? 'grid-cols-[auto_1fr_auto]' : 'grid-cols-[auto_1fr]'
        } [&>.input-group-shim]:!min-w-8 [&>.input-group-shim]:max-md:block`}
      >
        <div
          class="input-group-shim max-md:max-w-40 max-md:overflow-hidden max-md:text-ellipsis max-md:whitespace-nowrap max-md:leading-8"
        >
          {prefix}
        </div>
        <input
          class={sharedInputClasses}
          type="text"
          id={fieldName}
          value={decodeURI(field?.replace(prefix, '') ?? '')}
          pattern={NOT_URL_PATTERN}
          onchange={(e) => {
            setField(encodeURI(prefix + e.currentTarget.value))
          }}
          onpaste={pasteHandler(setField)}
          oncopy={copyHandler}
          {required}
        />
        {#if required}
          {@render invalidIcon(fieldName, field)}
        {/if}
      </div>
      {@render invalidHoverInfo(fieldName)}
    {/snippet}

    <label for="metadataType">Metadata Type:</label>
    <Select
      name="metadataType"
      placeholder="Select Type"
      required
      value={optimizationInputValues.labelingInfo.type}
      onchange={(e) => {
        switch (e.currentTarget.value) {
          case DatasetMetadataType.HirundoCSV:
            optimizationInputValues.labelingInfo = new HirundoCSV()
            break
          case DatasetMetadataType.COCO:
            optimizationInputValues.labelingInfo = new COCO()
            break
          case DatasetMetadataType.YOLO:
            optimizationInputValues.labelingInfo = new YOLO()
            break
        }
      }}
    >
      <option selected value>Select type</option>
      {#each datasetMetadataTypesValues as metadataType}
        <option value={metadataType}>{displayMetadataTypes[metadataType]}</option>
      {/each}
    </Select>

    {#if isYOLO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="data-root-url">Images directory URL:</label>
    {:else}
      <label for="data-root-url">Data root URL:</label>
    {/if}
    {@render metadataFields({
      fieldName: 'dataRootUrl',
      field: optimizationInputValues.dataRootUrl,
      setField: (value: string) => {
        optimizationInputValues.dataRootUrl = value
      },
      required: false,
    })}

    {#if isHirundoCSV(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="csvUrl">CSV URL:</label>
      {@render metadataFields({
        fieldName: 'csvUrl',
        field: optimizationInputValues.labelingInfo['csvUrl'],
        setField: (value: string) => {
          if (
            isHirundoCSV(
              optimizationInputValues.labelingInfo,
              optimizationInputValues.labelingInfo.type,
            )
          ) {
            optimizationInputValues.labelingInfo.csvUrl = value
            if (value.replace(prefix, '')) {
              invalids.csvUrl.setValid()
            } else {
              invalids.csvUrl.setInvalid('CSV URL is required')
            }
          }
        },
      })}
    {:else if isCOCO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="jsonUrl">JSON URL:</label>
      {@render metadataFields({
        fieldName: 'jsonUrl',
        field: optimizationInputValues.labelingInfo['jsonUrl'],
        setField: (value: string) => {
          if (
            isCOCO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)
          ) {
            optimizationInputValues.labelingInfo.jsonUrl = value
            if (value.replace(prefix, '')) {
              invalids.jsonUrl.setValid()
            } else {
              invalids.jsonUrl.setInvalid('JSON URL is required')
            }
          }
        },
      })}
    {:else if isYOLO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="labelsDirUrl">Labels directory URL:</label>
      {@render metadataFields({
        fieldName: 'labelsDirUrl',
        field: optimizationInputValues.labelingInfo['labelsDirUrl'],
        setField: (value: string) => {
          if (
            isYOLO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)
          ) {
            optimizationInputValues.labelingInfo.labelsDirUrl = value
            if (value.replace(prefix, '')) {
              invalids.labelsDirUrl.setValid()
            } else {
              invalids.labelsDirUrl.setInvalid('Labels directory URL is required')
            }
          }
        },
      })}
    {/if}

    {#if optimizationInputValues.labelingType == LabelingType.SpeechToText}
      <label for="language">Language:</label>
      <Select
        name="language"
        placeholder="Select language"
        required
        bind:value={optimizationInputValues.language}
      >
        <option selected value>Select language</option>
        <option value="ar">Arabic</option>
        <option value="he">Hebrew</option>
      </Select>
    {:else}
      <label for="classes">Classes:</label>
      <div class="space-y-2">
        {#if isYOLO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
          <label class="flex items-center space-x-2">
            <input
              class="radio"
              type="radio"
              checked={selectedClassesOption === ClassesOption.AutoDetection}
              name="radio-direct"
              value="1"
              onchange={() => {
                selectedClassesOption = ClassesOption.AutoDetection
                optimizationInputValues.classes = []
              }}
            />
            <label for="dataYamlUrl">From data YAML URL:</label>
            {@render metadataFields({
              fieldName: 'dataYamlUrl',
              field: optimizationInputValues.labelingInfo['dataYamlUrl'],
              setField: (value: string) => {
                if (
                  isYOLO(
                    optimizationInputValues.labelingInfo,
                    optimizationInputValues.labelingInfo.type,
                  )
                ) {
                  if (value == '') {
                    optimizationInputValues.labelingInfo.dataYamlUrl = null
                  }
                  optimizationInputValues.labelingInfo.dataYamlUrl = value
                }
              },
              required: false,
            })}
          </label>
        {:else}
          <label class="flex items-center space-x-2">
            <input
              class="radio"
              type="radio"
              checked={selectedClassesOption === ClassesOption.AutoDetection}
              name="radio-direct"
              value="1"
              onchange={() => {
                selectedClassesOption = ClassesOption.AutoDetection
                optimizationInputValues.classes = []
              }}
            />
            <p>Auto-detection</p>
          </label>
        {/if}
        <label class="flex items-center space-x-2">
          <input
            class="radio"
            type="radio"
            name="radio-direct"
            value="2"
            checked={selectedClassesOption === ClassesOption.ManualInput}
            onchange={() => (selectedClassesOption = ClassesOption.ManualInput)}
          />
          <label for="classes-chips">Manually input:</label>
          <InputChip
            bind:value={optimizationInputValues.classes}
            name="classes-chips"
            placeholder="Type a class and hit Enter "
            disabled={selectedClassesOption === ClassesOption.AutoDetection}
            allowUpperCase
          />
        </label>
      </div>
    {/if}

    <footer class="modal-footer {parent.regionFooter}">
      <div class="flex w-full items-center justify-between">
        <div class="btn-group space-x-2">
          <button
            class="variant-filled-primary {parent.buttonPositive}"
            type="button"
            onclick={createHandler}
            disabled={isLoading}
          >
            {parent.buttonTextSubmit}
          </button>
          <button
            class="variant-filled-error"
            type="button"
            onclick={parent.onClose}
            disabled={isLoading}
          >
            {parent.buttonTextCancel}
          </button>
        </div>
        {#if !isEditMode}
          <div class="btn-group">
            <button
              class="variant-filled-primary"
              type="button"
              onclick={createAndRunHandler}
              disabled={isLoading}
            >
              <Icon icon="iconoir:play-solid" />
            </button>
          </div>
        {/if}
      </div>
    </footer>
  </form>
{/if}

<style lang="postcss">
  form {
    flex-direction: column;
  }
  form > :not(:first-child) {
    margin: 0.5rem 0;
  }

  form input.invalid {
    @apply input-error;
  }
  input[type='search']::-webkit-search-cancel-button {
    filter: invert(1); /* Invert to white if the background is dark */
  }
  .disable-accordion {
    @apply pointer-events-none cursor-not-allowed opacity-50;
  }
</style>
