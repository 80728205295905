<script lang="ts">
  import Icon from '@iconify/svelte'
  import { getModalStore } from '@skeletonlabs/skeleton'
  import type { SvelteComponent } from 'svelte'

  import { prepareInputValuesForQuery } from './storage-configs-store'
  import StorageFormFields from './StorageFormFields.svelte'
  import { loggedInStore } from '@stores/logged-in-store'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  let { parent }: Props = $props()

  const modalStore = getModalStore()

  let storageConfig = $modalStore[0].value
  let selectedStorageType = $state(storageConfig?.type || null)
  let storageConfigName = $state(storageConfig?.name || '')
  let currentOrganizationId = storageConfig?.organizationId || $loggedInStore.primaryOrganizationId

  let inputValues = $state({})
  let storageConfigFormElement: HTMLFormElement | undefined = $state()

  const submitHandler = () => {
    if (!storageConfigFormElement?.reportValidity()) return
    if (!selectedStorageType) {
      console.error('Tried to create a storage config without a storage type')
      return
    }

    const payload = prepareInputValuesForQuery(inputValues, selectedStorageType)
    if (payload.git && storageConfig?.git?.repo?.id) {
      payload.git.repo = {
        id: storageConfig.git.repo.id,
        ...payload.git.repo,
      }
    }
    payload.organizationId = currentOrganizationId
    payload.name = storageConfigName
    payload.type = selectedStorageType

    if (!$modalStore[0].response) {
      console.error("Modal doesn't have a response function")
      return
    }
    $modalStore[0].response({ payload, closeModal: parent.onClose })
  }
</script>

{#if $modalStore[0]}
  <div
    class="card relative m-3 h-[30rem] max-h-[80vh] w-[38rem] max-w-[38rem] space-y-4 justify-self-center overflow-y-scroll p-6 md:min-w-[30rem]"
  >
    <h3 class="h3 justify-between">
      {$modalStore[0].title}
      <button
        class="absolute right-2 top-2 text-surface-400 hover:text-surface-300"
        type="button"
        onclick={parent.onClose}
      >
        <Icon icon="iconoir:xmark-circle" />
      </button>
    </h3>
    <span>{$modalStore[0].body}</span>
    <StorageFormFields
      bind:storageFormElement={storageConfigFormElement}
      bind:inputValues
      bind:selectedStorageType
      bind:storageConfigName
      {storageConfig}
    ></StorageFormFields>
    <footer class="modal-footer {parent.regionFooter}">
      <div class="variant-ghost-surface btn-group w-min">
        <button
          class="variant-filled-primary {parent.buttonPositive}"
          type="submit"
          onclick={submitHandler}
        >
          {parent.buttonTextSubmit}
        </button>
        <button class="variant-filled-error" type="button" onclick={parent.onClose}>
          Cancel
        </button>
      </div>
    </footer>
  </div>
{/if}

<style>
  .card {
    flex-direction: column;
    width: fit-content;
  }
  .card > * {
    margin: 0.5rem 0;
  }
</style>
