<script lang="ts">
  import { popup, type PopupSettings } from '@skeletonlabs/skeleton'
  import { onMount } from 'svelte'
  import type { ChangeEventHandler } from 'svelte/elements'

  import {
    displayStorageTypes,
    getPattern,
    type IGitRepo,
    isGCSField,
    isGit,
    isGitField,
    isS3Field,
    type IStorageConfig,
    type IStorageGit,
    storageFields,
    StorageType,
    storageTypesValues,
  } from './storage-configs-store'
  import Select from '@components/Select.svelte'
  import {
    organizationAsyncState,
    organizationsStore,
  } from '@routes/organizations/organizations-store'
  import { loggedInStore } from '@stores/logged-in-store'
  import { NAME_PATTERN, nameValidator, ValidityState } from '@utils/validation.svelte'

  interface Props {
    storageFormElement?: HTMLFormElement
    storageConfig?: IStorageConfig | null
    selectedStorageType?: StorageType | null
    storageConfigName?: string
    inputValues?: { [key: string]: string | undefined }
    currentOrganizationId?: number
  }

  let {
    storageFormElement = $bindable(),
    storageConfig = null,
    selectedStorageType = $bindable(storageConfig?.type || null),
    storageConfigName = $bindable(storageConfig?.name || ''),
    inputValues = $bindable({}),
    currentOrganizationId = $bindable($loggedInStore.primaryOrganizationId),
  }: Props = $props()

  const invalids = $state({
    name: new ValidityState(),
  })

  const getFromGitRepo = (fieldName: string) => {
    if (fieldName.includes('repo.')) {
      const innerKey = fieldName.split('repo.')[1] as keyof IGitRepo
      return storageConfig?.git?.repo[innerKey] as string
    }
    return storageConfig?.git?.[fieldName as keyof IStorageGit] as string
  }

  onMount(() => {
    if (!storageConfig) {
      return
    }
    selectedStorageType = storageConfig.type
    storageFields[selectedStorageType].forEach((field) => {
      const fieldName = field.fieldName
      if (isS3Field(storageConfig?.s3, selectedStorageType, fieldName)) {
        inputValues[fieldName] = storageConfig.s3?.[fieldName]
      } else if (isGCSField(storageConfig?.gcp, selectedStorageType, fieldName)) {
        inputValues[fieldName] = storageConfig.gcp?.[fieldName]
      } else if (isGitField(storageConfig?.git, selectedStorageType, fieldName)) {
        inputValues[fieldName] = getFromGitRepo(fieldName)
      }
    })
    // Add manually fields that are not part of the storage config object
    if (storageConfig?.s3) {
      inputValues['secretAccessKey'] = '' // Leave the secretAccessKey empty for security reasons
    }
  })

  const onStorageTypeChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    selectedStorageType = event.currentTarget.value as StorageType
    if (selectedStorageType) {
      storageFields[selectedStorageType].forEach((field) => {
        inputValues[field.fieldName] = ''
      })
      if (isGit(storageConfig, selectedStorageType)) {
        inputValues['repo.authType'] = 'none'
      }
    }
  }

  const onLoadHandler = (fieldName: string) => (e: ProgressEvent<FileReader>) => {
    try {
      inputValues[fieldName] = JSON.parse(e.target?.result?.toString() || '')
    } catch (error) {
      console.error('Failed to parse JSON file', error)
    }
  }

  const handleFileUpload =
    (fieldName: string) => (event: Event & { currentTarget: HTMLInputElement }) => {
      const file = event.currentTarget.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = onLoadHandler(fieldName)
        reader.readAsText(file)
      }
    }

  const sharedInputClasses = 'input my-0 box-border flex w-fit md:min-w-96 px-2 py-1'

  const disabledEditStorageTypePopup: PopupSettings = {
    event: 'hover',
    placement: 'right',
    target: 'disabledEditStorageType',
  }
</script>

<form bind:this={storageFormElement} class="max-wfull w-full space-y-4">
  <label for="name">Storage config name:</label>
  <input
    class={sharedInputClasses + ' autocomplete'}
    type="text"
    id="name"
    oninvalid={nameValidator(invalids.name)}
    onchange={nameValidator(invalids.name)}
    bind:value={storageConfigName}
    required
    pattern={NAME_PATTERN.source}
  />
  <label for="organization-to-associate-with">Associated organization:</label>
  {#if $organizationAsyncState.loading}
    <p>Loading organizations...</p>
  {:else if $organizationAsyncState.error}
    <p>{$organizationAsyncState.error}</p>
  {:else}
    <Select required name="organization-to-associate-with" bind:value={currentOrganizationId}>
      <option selected value>Select an organization</option>
      {#each $organizationsStore as [_id, organization] (organization.id)}
        <option value={organization.id} selected={organization.id === currentOrganizationId}>
          {organization.name}
        </option>
      {/each}
    </Select>
  {/if}
  <label for="storage-type">Storage type:</label>
  <span use:popup={disabledEditStorageTypePopup}>
    <div
      class={`card variant-filled-warning z-50 p-2 ${storageConfig ? 'visible' : 'invisible'}`}
      data-popup={'disabledEditStorageType'}
    >
      <p>You cannot change storage type while editing</p>
      <div class="variant-filled-warning arrow"></div>
    </div>
    <Select
      required
      name="storage-type"
      bind:value={selectedStorageType}
      placeholder="Select Type"
      disabled={!!storageConfig}
      onchange={onStorageTypeChange}
    >
      <option selected value>Select type</option>
      {#each storageTypesValues as storageType}
        {#if window.airGapped || storageType !== StorageType.Local}
          {#if storageType === selectedStorageType}
            <option value={storageType} selected>{displayStorageTypes[storageType]}</option>
          {:else}
            <option value={storageType}>{displayStorageTypes[storageType]}</option>
          {/if}
        {/if}
      {/each}
    </Select>
  </span>
  {#if selectedStorageType}
    {#each storageFields[selectedStorageType] as field}
      {#if !field.showField || field.showField(inputValues)}
        <div class="relative space-y-4 pl-4">
          <label for={field.fieldName}>
            {field.label}
          </label>
          {#if field.type === 'file'}
            <input
              class={sharedInputClasses}
              type="file"
              id={field.fieldName}
              name={field.fieldName}
              accept={field.accept}
              onchange={handleFileUpload(field.fieldName)}
              required={field.required}
            />
          {:else if isS3Field(storageConfig?.s3, selectedStorageType, field.fieldName) && field.fieldName === 'bucketUrl'}
            <div class="input input-group input-group-divider grid-cols-[auto_1fr_auto]">
              <div class="input-group-shim">s3://</div>
              <input
                class={sharedInputClasses}
                type="text"
                id="bucketUrl"
                name="bucketUrl"
                value={(inputValues.bucketUrl || '').replace('s3://', '')}
                oninput={(e) => (inputValues.bucketUrl = 's3://' + e.currentTarget.value)}
                required={field.required}
                placeholder="my-bucket-name"
              />
            </div>
          {:else if field.type == 'select'}
            <Select bind:value={inputValues[field.fieldName]} required={field.required}>
              {#each Object.entries(field.options) as [optionValue, optionName], i}
                {#if i === 0}
                  <option value={optionValue} selected>{optionName}</option>
                {:else}
                  <option value={optionValue}>{optionName}</option>
                {/if}
              {/each}
            </Select>
          {:else}
            <input
              class={sharedInputClasses + ' autocomplete'}
              type={field.type}
              id={field.fieldName}
              name={field.fieldName}
              value={inputValues[field.fieldName] || ''}
              oninput={(e) => {
                inputValues[field.fieldName] = e.currentTarget?.value
              }}
              autocomplete={field.autocomplete}
              required={field.required}
              pattern={getPattern(field)}
            />
          {/if}
        </div>
      {/if}
    {/each}
  {/if}
</form>
